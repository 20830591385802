import { makeAutoObservable, when } from 'mobx'
import { ReactNode } from 'react'

type AlertCallbacks = {
  title: string
  content: string | ReactNode
  onAgree?: () => void
  onDisagree?: () => void
}

type TAction = 'agree' | 'disagree'

export class AlertDialogStore {
  isOpen: boolean = false

  title: string = ''

  content: string | ReactNode = ''

  action: TAction | undefined = undefined

  constructor() {
    makeAutoObservable(this)
  }

  openAlert = ({ title, content, onAgree, onDisagree }: AlertCallbacks): void => {
    this.title = title
    this.content = content

    this.isOpen = true

    const reaction = when(
      () => !!this.action,
      () => {
        if (this.action === 'agree' && onAgree) onAgree()
        if (this.action === 'disagree' && onDisagree) onDisagree()

        this.action = undefined

        reaction()
      }
    )
  }

  closeAlert = (action: TAction): void => {
    this.title = ''
    this.content = ''
    this.action = action

    this.isOpen = false
  }

  onAgree = (): void => {
    this.closeAlert('agree')
  }

  onDisagree = (): void => {
    this.closeAlert('disagree')
  }
}
