import { FC, ReactNode } from 'react'
import { Alert, Box, Stack, Typography } from '@mui/material'
import { BlockBtn, DeleteBtn, EditBtn, SaveBtn, UnBlockBtn } from '../index'

type WrapDrawerEditFormProps = {
  children: ReactNode
  title: string
  busyText: string
  isBusy: boolean
  isShowEditBtn?: boolean
  isShowSaveBtn?: boolean
  isShowUnBlockBtn?: boolean
  isShowBlockBtn?: boolean
  isShowDeleteBtn?: boolean
  onEdit?: () => void
  onBlock?: () => void
  onUnBlock?: () => void
  onSave?: () => void
  onDelete?: () => void
  additionalButtons?: ReactNode
}

const WrapDrawerEditForm: FC<WrapDrawerEditFormProps> = ({
  children,
  title,
  busyText,
  isBusy,
  isShowDeleteBtn = true,
  isShowEditBtn = true,
  isShowSaveBtn = false,
  isShowBlockBtn = true,
  isShowUnBlockBtn = false,
  onSave,
  onEdit,
  onDelete,
  onBlock,
  onUnBlock,
  additionalButtons,
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: '1fr auto',
        height: '100%',
        gap: 1,
        minWidth: '240px',
        width: '280px',
        p: 2,
      }}>
      <Box>
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center',
          }}>
          {title}
        </Typography>
        {isBusy && (
          <Alert severity="warning" sx={{ m: 1 }}>
            {busyText}
          </Alert>
        )}
        <Stack direction="column" gap={2}>
          {children}
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
          }}>
          {!isBusy && (
            <>
              {isShowEditBtn && (
                <EditBtn
                  onClick={() => {
                    if (onEdit) onEdit()
                  }}
                />
              )}
              {isShowSaveBtn && (
                <SaveBtn
                  onClick={() => {
                    if (onSave) onSave()
                  }}
                />
              )}
              {isShowBlockBtn && (
                <BlockBtn
                  onClick={() => {
                    if (onBlock) onBlock()
                  }}
                />
              )}

              {isShowUnBlockBtn && (
                <UnBlockBtn
                  onClick={() => {
                    if (onUnBlock) onUnBlock()
                  }}
                />
              )}
              {isShowDeleteBtn && (
                <DeleteBtn
                  onClick={() => {
                    if (onDelete) onDelete()
                  }}
                />
              )}
            </>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
          }}>
          {additionalButtons}
        </Box>
      </Box>
    </Box>
  )
}

export default WrapDrawerEditForm
