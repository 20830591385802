import { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { observer, useLocalObservable } from 'mobx-react-lite'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import TelegramIcon from '@mui/icons-material/Telegram'
import { TextField } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { get } from 'lodash'
import { ECurrency, symbolCurrency } from '@lbs/currency'
import { EPlayerStatus } from '@lbs/status'
import { ChangePasswordBtn, CrudIconBtn, Dialog, DialogAddPlayerBalance, Select, WrapDrawerEditForm } from '../index'
import PlayerEditStore from '../../stores/PlayerEdit.store'
import { useStore } from '../../stores'
import { formPropsDrawerCrud } from '../../constants/formPropsDrawerCrud'
import { playerStatus } from '../../constants/player.status'
import { getDateFormat, getDateFormatSql } from '../../utils/date'
import { ApiCoupon, ApiPlayers } from '../../api'

type PlayerEditProps = {
  playerId: string
}

const PlayerEdit: FC<PlayerEditProps> = observer(({ playerId }) => {
  const navigate = useNavigate()
  const [showBalanceDialog, setShowBalanceDialog] = useState(false)
  const [dialogInvite, setDialogInvite] = useState(false)
  const [passwordDialog, setPasswordDialog] = useState({
    isShow: false,
    password: '',
    confirmPassword: '',
  })
  const [inviteCode, setInviteCode] = useState(0)
  const [edit, setEdit] = useState(false)
  const { loginStore, drawerCRUDStore, alertDialogStore } = useStore()
  const player = useLocalObservable(() => {
    return new PlayerEditStore({})
  })

  const { refetch } = useQuery(
    [`get player by id: ${playerId}`, playerId],
    async () => {
      return ApiPlayers.getPlayerById(playerId)
    },
    {
      enabled: !!playerId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        player.updateFields(data)
      },
      onError: (e) => {
        toast.error(`${e}`)
      },
    }
  )

  useQuery(
    [`get coupons player by id: ${playerId}`, playerId],
    async () => {
      return ApiPlayers.getCouponsPlayerById(playerId)
    },
    {
      enabled: !!playerId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        player.updateCoupons(data.coupons)
      },
      onError: () => toast.error('Failed load coupons'),
    }
  )

  const couponCode = get(player.coupons[0], ['data', 'code'], 0)

  const handleClosePasswordDialog = (): void => {
    setPasswordDialog({
      isShow: false,
      password: '',
      confirmPassword: '',
    })
  }

  return (
    <>
      <WrapDrawerEditForm
        busyText=""
        isBusy={false}
        title={`Просмотр игрока ${player.login}`}
        isShowEditBtn={!edit && !player.isDeleted}
        isShowSaveBtn={edit}
        isShowBlockBtn={!player.isBlocked && !player.isDeleted && !player.isNotActive}
        onBlock={async () => {
          try {
            const data = {
              status: EPlayerStatus.BLOCKED,
            }

            await ApiPlayers.updateFieldsPlayerById(playerId, data)

            player.updateFields(data)

            toast.success(`Игрок ${player.login} успешно заблокирован`)
          } catch (e) {
            toast.error(`${e}`)
          }
        }}
        isShowUnBlockBtn={player.isBlocked}
        onUnBlock={async () => {
          try {
            const data = {
              status: EPlayerStatus.ACTIVE,
            }

            await ApiPlayers.updateFieldsPlayerById(playerId, data)

            player.updateFields(data)

            toast.success(`Игрок ${player.login} успешно разблокирован`)
          } catch (e) {
            toast.error(`${e}`)
          }
        }}
        isShowDeleteBtn={!player.isDeleted && !player.isNotActive}
        onDelete={async () => {
          try {
            await ApiPlayers.deletePlayerById(playerId)

            toast.success(`Игрок ${player.login} успешно удален`)

            drawerCRUDStore.resetView()
          } catch (e) {
            toast.error(`${e}`)
          }
        }}
        onEdit={() => {
          setEdit(true)
        }}
        onSave={async () => {
          try {
            const { login, phones, rouletteRatio, currency } = player

            await ApiPlayers.updateFieldsPlayerById(playerId, {
              login,
              phones,
              rouletteRatio,
              currency,
            })

            toast.success(`Игрок ${player.login} успешно обновлен`)
            setEdit(false)
          } catch (e) {
            toast.error(`${e}`)
          }
        }}
        additionalButtons={
          <>
            {loginStore.isAdministrator && player.isActive && (
              <>
                <CrudIconBtn
                  color="primary"
                  tooltip="Изменить баланс игрока"
                  icon={<AttachMoneyIcon />}
                  onClick={async () => {
                    setEdit(false)

                    setShowBalanceDialog(true)
                  }}
                />

                <CrudIconBtn
                  color="primary"
                  tooltip="Сброс баланса"
                  icon={<MoneyOffIcon color="warning" />}
                  onClick={() => {
                    alertDialogStore.openAlert({
                      title: `Подтвердите сброс баланса игрока: ${player.login}`,
                      content:
                        'Баланс игрока будет установлен на ноль. Также будет удалена вся финансовая история и история транзакций',
                      onAgree: async () => {
                        try {
                          await ApiPlayers.resetBalance(playerId)

                          toast.success(`Успешный сброс баланса и истории у ${player.login}`)
                        } catch (e) {
                          toast.error(`${e}`)
                        }
                      },
                    })
                  }}
                />

                <ChangePasswordBtn
                  onClick={() => {
                    setPasswordDialog({
                      ...passwordDialog,
                      isShow: true,
                    })
                  }}
                />
              </>
            )}
            <CrudIconBtn
              color="primary"
              tooltip="Финансовая история игрока"
              icon={<AccountBalanceWalletIcon />}
              onClick={() => {
                drawerCRUDStore.resetView()

                navigate(`/player/${playerId}/finance`)
              }}
            />
            {player.isNotActive && player.coupons.length === 0 && (
              <CrudIconBtn
                tooltip="Выслать приглашение"
                icon={<TelegramIcon />}
                color="secondary"
                onClick={async () => {
                  try {
                    const { code } = await ApiCoupon.getNewCode()

                    setInviteCode(code)
                    setDialogInvite(true)
                  } catch (e) {
                    toast.error(`${e}`)
                  }
                }}
              />
            )}
          </>
        }>
        <TextField
          {...formPropsDrawerCrud}
          disabled={!edit}
          label="Логин"
          value={player.login}
          onChange={(e) => {
            e.preventDefault()
            player.updateFields({
              login: e.target.value,
            })
          }}
        />
        {player.phones.map((item, index) => {
          return (
            <MuiTelInput
              key={item.priority}
              {...formPropsDrawerCrud}
              label={`Номер телефона ${item.priority}`}
              value={item.number}
              onChange={(e) => {
                player.changeNumber(e, index)
              }}
              disabled={!edit}
            />
          )
        })}

        <TextField {...formPropsDrawerCrud} label="Статус" value={playerStatus[player.status]} disabled />

        <TextField
          {...formPropsDrawerCrud}
          label="Баланс"
          value={`${player.balance} ${symbolCurrency[player.currency]}`}
          disabled
        />

        <TextField
          {...formPropsDrawerCrud}
          label="Коэффициент для рулетки"
          type="number"
          value={player.rouletteRatio}
          disabled={!edit}
          onChange={(e) => {
            e.preventDefault()
            player.updateFields({
              rouletteRatio: Number(e.target.value),
            })
          }}
        />

        <Select
          {...formPropsDrawerCrud}
          id="currency"
          label="Валюта"
          value={player.currency}
          options={Object.values(ECurrency).map((item) => {
            return {
              value: item,
              label: `${symbolCurrency[item]} (${item})`,
            }
          })}
          onChange={(e) => {
            player.updateFields({ currency: e as ECurrency })
          }}
          disabled={!edit}
        />

        <TextField
          {...formPropsDrawerCrud}
          label="Дата регистрации"
          value={player.createdAt ? getDateFormat(new Date(player.createdAt)) : '-'}
          disabled
        />
        <TextField
          {...formPropsDrawerCrud}
          label="Дата активации"
          value={player.activatedAt ? getDateFormat(new Date(player.activatedAt)) : '-'}
          disabled
        />
        {player.coupons.length > 0 && player.isNotActive && (
          <TextField {...formPropsDrawerCrud} label="Код приглашения" value={couponCode} disabled />
        )}
      </WrapDrawerEditForm>

      {showBalanceDialog && (
        <DialogAddPlayerBalance
          playerId={playerId}
          playerLogin={player.login}
          isOpen={showBalanceDialog}
          onSave={async () => {
            try {
              await refetch()
            } catch (e) {
              toast.error(`${e}`)
            }
          }}
          onClose={() => {
            setShowBalanceDialog(false)
          }}
        />
      )}

      <Dialog
        open={dialogInvite}
        title={`Выслать приглашение ${player.login}`}
        saveText="Подтвердить"
        onClose={() => {
          setDialogInvite(false)
        }}
        onSave={async () => {
          try {
            await ApiCoupon.registerInvitePlayer({
              type: 'register',
              data: { code: inviteCode, message: '', playerPhone: player.phones[0].number },
              resend: false,
              sendAt: getDateFormatSql(new Date()),
              createdFor: playerId,
            })

            toast.success(`Код регистрации ${inviteCode} успешно сохранен`)

            drawerCRUDStore.resetView()
          } catch (e) {
            toast.error(`${e}`)
          }
        }}>
        Ваш код регистрации: {inviteCode}. Запустите приложение на планшете, нажмите РЕГИСТРАЦИЯ.
      </Dialog>

      <Dialog
        open={passwordDialog.isShow}
        title={`Изменения пароля для ${player.login}`}
        tooltip="Минимум 3 символа"
        disableSave={
          passwordDialog.password !== passwordDialog.confirmPassword || passwordDialog.confirmPassword.length < 3
        }
        onClose={() => {
          handleClosePasswordDialog()
        }}
        onSave={async () => {
          try {
            await ApiPlayers.changePassword(playerId, {
              newPassword: passwordDialog.confirmPassword,
            })

            toast.success(`Пароль успешно изменен`)
          } catch (e) {
            toast.error(`${e}`)
          } finally {
            handleClosePasswordDialog()
          }
        }}>
        <TextField
          {...formPropsDrawerCrud}
          error={passwordDialog.password !== passwordDialog.confirmPassword}
          label="Пароль"
          value={passwordDialog.password}
          onChange={(e) => {
            setPasswordDialog({
              ...passwordDialog,
              password: e.target.value,
            })
          }}
        />
        <TextField
          {...formPropsDrawerCrud}
          error={passwordDialog.password !== passwordDialog.confirmPassword}
          label="Подтверждение пароля"
          value={passwordDialog.confirmPassword}
          onChange={(e) => {
            setPasswordDialog({
              ...passwordDialog,
              confirmPassword: e.target.value,
            })
          }}
        />
      </Dialog>
    </>
  )
})

export default PlayerEdit
