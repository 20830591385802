import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

const AlertDialog: FC = observer(() => {
  const { alertDialogStore } = useStore()
  const { isOpen, title, content, closeAlert, onAgree, onDisagree } = alertDialogStore

  if (!isOpen) return null

  return (
    <Dialog open={isOpen} onClose={() => closeAlert('disagree')}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onDisagree} variant="outlined">
          Отклонить
        </Button>
        <Button onClick={onAgree} variant="contained" autoFocus>
          Согласен
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default AlertDialog
