import { Outlet } from 'react-router-dom'
import React, { FC } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import AlertDialog from '../../components/AlertDialog'

const Common: FC = () => {
  return (
    <>
      <Outlet />

      <AlertDialog />
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}

export default Common
